import { toInteger } from "lodash";
import styled from "styled-components";
import ImageWithFallback from "./ImageWithFallback";

const PostWrap = styled.a`
  display: flex;
  align-items: center;
  margin: 15px 0;
  transition: transform 0.2s ease;
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  position: relative;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }

  &:hover {
    transform: scale(0.99);
  }

  & > img {
    width: 100px;
    height: 100px;
    max-width: 100%;
    border-radius: 15px;
    object-fit: cover;

    @media only screen and (max-width: 800px) {
      height: 200px;
      width: 100%;
    }
    @media only screen and (max-width: 600px) {
      height: 100px;
      width: 100%;
    }
  }

  &:hover {
    /* transform: scale(0.99); */
  }

  .content {
    margin-left: 20px;
    color: ${({ theme }) => theme.textColor};
    width: 620px;
    max-width: 100%;

    @media only screen and (min-width: 900px) {
      max-width: calc(100% - 120px);
    }
    @media only screen and (max-width: 900px) {
      width: 300px;
    }

    @media only screen and (max-width: 800px) {
      padding-right: 10px;
      margin: 0;
      margin-top: 10px;
    }

    h3 {
      margin: 0;
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.2;
      color: ${({ theme }) => theme.textColor};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media only screen and (max-width: 800px) {
        -webkit-line-clamp: 2;
        margin-bottom: 10px;
      }

      &.news-event-bold {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0;
        margin-top: 5px;
      }
    }

    p {
      margin: 0;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: ${({ theme }) => theme.textColor};

      @media only screen and (max-width: 800px) {
        font-size: 14px;
      }
    }
    .tags {
      margin-top: 10px;
      line-height: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      gap: 10px;
    }
    .tag {
      background: ${({ theme }) => theme.hoverBackground};
      display: inline;
      padding: 3px 10px;
      border-radius: 25px;
      white-space: nowrap;

      &.active-tag {
        background: ${({ theme }) => theme.blue};
        color: white;
      }
    }
  }
`;

const DateBox = styled.div`
  height: 100px;
  width: 100px;
  background: ${({ theme }) => theme.hoverBackground};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 800px) {
    width: 100%;
    padding: 5px 0;
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }
  .month {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    @media only screen and (max-width: 800px) {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
  .date {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #2f73da;
    line-height: 1;
    @media only screen and (max-width: 800px) {
      font-size: 33px;
      margin-top: 0;
    }
  }
`;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function getGenericThumbnail(postId) {
  // Define the directory and the range of image names
  const directory = "https://assets.mx.com/hub/images/post-images";
  const maxIndex = 21; // Maximum index of images (0 to 21)

  // Use the ID to pick an image index
  const imageIndex = postId % (maxIndex + 1); // Map to a valid image index

  // Construct the image filename
  const imageName = `${imageIndex}.jpeg`;

  // Return the full path to the image
  return `${directory}/${imageName}`;
}

const PostRow = ({
  title,
  description,
  tags,
  activeTag,
  link,
  image,
  postId,
  postType,
  postRowType,
  onClick,
  id,
  external,
  dateBox,
  className = "",
}) => {
  let featuredImage = "https://assets.mx.com/hub/images/no-img-img.png";
  if (image) {
    featuredImage = `https://directus.hub.mx.com/assets/${image}`;
  } else {
    console.log("no img", getGenericThumbnail(postId));

    featuredImage = getGenericThumbnail(postId);
  }
  return (
    <PostWrap
      href={link ? link : "#"}
      target={external ? "_blank" : "_self"}
      onClick={onClick}
      className={`${className} post-row `}
      id={id}
    >
      {dateBox ? (
        <DateBox className="date-box">
          <p className="month">
            {months[toInteger(dateBox.split("-")[1]) - 1]}
          </p>
          <p className="date">{toInteger(dateBox.split("-")[2])}</p>
        </DateBox>
      ) : (
        <ImageWithFallback
          fallbackSrc="https://assets.mx.com/hub/images/no-img-img.png"
          size={400}
          src={featuredImage}
        />
      )}
      <div className="content">
        <p className="post-type">{postType}</p>
        <h3 className={postRowType == "news_event" ? "news-event-bold" : ""}>
          {title}
        </h3>
        <p>{description ? description : ""}</p>
        <div className="tags">
          {tags &&
            tags.map((tag, index) => {
              if (tags.length > 5) {
                tags.splice(0, 5);
              }
              let tagClass = "tag";
              if (activeTag && tag == activeTag) {
                tagClass += " active-tag";
              }
              return (
                <div key={index} className={tagClass}>
                  {tag}
                </div>
              );
            })}
        </div>
      </div>
    </PostWrap>
  );
};

export default PostRow;
